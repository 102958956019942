import Image from 'next/image'
import type { FC } from 'react'
import { memo, useEffect, useRef, useState } from 'react'

import PlayIcon from '@iconscout/unicons/svg/line/play.svg'

import { Description, PerformanceBlock, PlayButton, VideoPlayerContainer } from './styles'

type Props = {
  description?: string
  imgSrc?: string
  isStopped?: boolean
  sizes: string
  videoSrc: string
}

const VideoPlayer: FC<Props> = ({ description, imgSrc, isStopped, sizes, videoSrc }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const performanceRef = useRef<HTMLDivElement | null>(null)

  const handlePlayClick = () => {
    setVideoPlaying(true)
  }

  useEffect(() => {
    isStopped && setVideoPlaying(false)
  }, [isStopped])

  return (
    <VideoPlayerContainer>
      {videoPlaying && (
        <iframe allow="autoplay" allowFullScreen={true} frameBorder="0" height="100%" src={videoSrc} width="100%" />
      )}
      <PerformanceBlock
        $isShow={!videoPlaying}
        $isShowDescription={!!description}
        onClick={handlePlayClick}
        ref={performanceRef}
      >
        <Image alt="video" fill loading="lazy" quality={60} sizes={sizes} src={imgSrc || ''} />
        <PlayButton aria-label="play video button">
          <PlayIcon />
        </PlayButton>
        {description && <Description size="s">{description}</Description>}
      </PerformanceBlock>
    </VideoPlayerContainer>
  )
}

export default memo(VideoPlayer)
